import React from "react";

import NessieImg from "../../images/Nessie_Koer.png";

type Props = {
  [key: string]: any;
};

export const Nessie: React.FC<Props> = ({ ...props } = {}) => {
  return <img src={NessieImg} alt="Nessie" {...props} />;
};
