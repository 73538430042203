import "./src/css/style.css";

import "timber-common/css/global.css";
import "timber-common/css/header.css";
import "timber-common/css/article.css";

import "react-medium-image-zoom/dist/styles.css";

import "@fontsource/nunito/200.css";
import "@fontsource/nunito/200-italic.css";
import "@fontsource/nunito/300.css";
import "@fontsource/nunito/300-italic.css";
import "@fontsource/nunito/400.css";
import "@fontsource/nunito/400-italic.css";
import "@fontsource/nunito/600.css";
import "@fontsource/nunito/700.css";
import "@fontsource/nunito/800.css";
import "@fontsource/nunito/900.css";

import { handleInitialClientLoad } from "timber-common";

export const onInitialClientRender = () => {
  handleInitialClientLoad();
};
